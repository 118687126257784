import {Container, Typography, Breadcrumbs, Link, Stack, IconButton, Tooltip} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// components
import Page from '../components/Page';
import DropZone from "../components/Dropzone"
import { httpClient } from '..';
import { useEffect, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { StripedDataGrid } from '../components/StripedDataGrid';
import { S3 } from '../constants'
import { handleDownload } from "../helpers/download.helper"
import SnackBar from '../components/Snackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { checkFileName, qualityControlsFileHandler, snackBarDefaultValues} from '../helpers/qualityControls.helper'
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

type Props = {
  customerName: string | undefined,
  digestedBucket: string | undefined
}

export default function QualityControl({customerName, digestedBucket}: Props) {

  const [openSnackbar, setSnackbar] = useState(snackBarDefaultValues)
  const { t, i18n } = useTranslation();

  const columns: GridColDef[] = [
    { field: 'date', headerName: t('qualityControls.columns.date'), flex: 1, minWidth: 100},
    { field: 'type', headerName: t('qualityControls.columns.type'), flex: 1, minWidth: 50},
    { field: 'fileName', headerName: t('qualityControls.columns.document'), flex: 5, minWidth: 300, renderCell: (param) => (
      <a href="#" onClick={(e) => handleDownload(e, param.row.Key, param.row.alias, digestedBucket as string, setSnackbar)}>{param.value}</a>
    )},
  ];

  const [files, setFiles] = useState<Object[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [linkExists, setLinkExists] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<string[]>([])

  useEffect(() => {
    selectedFiles.forEach((file) => {
      if (!linkExists){
        httpClient.get(`quality_controls/${file.replace('.pdf', '').replaceAll(' ', '-')}/link`).then((response) => setLinkExists(response.data === true ? true : false))
      }
    })
    if (selectedFiles.length === 0) { setLinkExists(false) }
    listObjects()
  }, [selectedFiles])

  function listObjects(){
    if (digestedBucket !== undefined){
      setLoading(true)
      S3.listObjects({
        Bucket: digestedBucket,
        Prefix: `QualityControls`
      }).promise().then((response) => {
        if (response?.Contents){
          setFiles(qualityControlsFileHandler(response.Contents))
        }
      }).catch(() => {
        setError(true)
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    listObjects()
  }, [digestedBucket])

  const callback = (fileName: string | undefined) => {
    if (fileName !== undefined){
      let data = { file_name: fileName.replaceAll(' ', '-') }
      httpClient.post('/quality_controls/', data).then((res) => {
        if (res?.data?.non_field_errors){
          setSnackbar({open: true, severity: "error", message: t('qualityControls.messages.fileExists', {customerName: customerName})})
        } else if (res.status === 201){
          setSnackbar({open: true, severity: "success", message: t('qualityControls.messages.uploadSuccess')})
        }
     
      })
    }
    listObjects()
  }

  function deleteFiles(){
    if (digestedBucket !== undefined){
      selectedFiles.forEach((file) => {
        S3.deleteObject({
          Bucket: digestedBucket,
          Key: `QualityControls/${file}`
        }).promise().then(() => {
          httpClient.delete(`/quality_controls/${file.replace('.pdf', '').replaceAll(' ', '-')}`).then((response) => {
            if (response.status === 200){
              setSnackbar({open: true, severity: "success", message: t('qualityControls.messages.deleteSuccess')})
              setSelectedFiles([])
              listObjects()
            }
          })
        })
      })
    }
  }

  if (error === true){
    return <SnackBar open={true} severity='error' message={t('qualityControls.messages.loadError')} />
  } else {
    return (
      <Page title="Quality Control">
        <Container maxWidth="xl">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
            <Link underline="hover" color="inherit" href="/dashboard/app">{t('qualityControls.breadcrumb.dashboard')}</Link>
            <Typography color="text.primary">{t('qualityControls.breadcrumb.qualityControl')}</Typography>
          </Breadcrumbs>
          <Typography variant="h4" mb={2}>
            {t('qualityControls.title', {customerName: customerName})}
          </Typography>
          {digestedBucket && <DropZone sampleId={'QualityControls'} callbackFunction={callback} checkFileName={checkFileName} bucketName={digestedBucket} acceptRule=".pdf" disabled={false}></DropZone>}
          <StripedDataGrid
            rows = {files}
            rowCount = {files.length}
            pageSize = {10}
            columns = {columns}
            getRowId={(row) => row.fileName}
            rowsPerPageOptions={[10]}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            autoHeight
            disableSelectionOnClick
            checkboxSelection={true}
            onSelectionModelChange={(keys) => {
              setSelectedFiles(keys as string[])
            }}
            sx={{marginTop: 1}}
            loading={loading}
          />
          <Stack direction="row" justifyContent="space-between">
            { linkExists === false ? (
              <IconButton onClick={() => deleteFiles()}><DeleteIcon /></IconButton> ) : (
              <Tooltip title={t('qualityControls.messages.linkExists')}><span><IconButton disabled><DeleteIcon /></IconButton></span></Tooltip>
              )}
            <IconButton onClick={() => listObjects()}><AutorenewIcon /></IconButton>
          </Stack>
          { openSnackbar.open && <SnackBar open={openSnackbar.open} severity={openSnackbar.severity} message={openSnackbar.message} />}
        </Container>
      </Page>
    );
  }
}