import { AxiosResponse } from "axios"
import i18n from '../../i18n'

export function handlePreviousData(response: AxiosResponse){
    // Replace the keys in the response data
    // response.data['hormono'] = response.data['hormono']
    // delete response.data['hormono']
}

export const defaultValues = (pk: number) => {
    return {
        test_type: "NGRB",
        birth_date: null,
        gender: null,
        patient_guid: "",
        ext_reference: "",
        diabetes: null,
        tobacco: null,
        hormono: null,
        blood_sample: {
            sample_id: "",
            customer: pk,
            internal_control: "",
            sample_datetime: null,
            cell_culture_start_time: null,
            cell_culture_end_time: null,
            irradiation_timestamp: null,
            lab_reception_user: "",
            lab_reception_form: false,
            usable_sample: 0,
            notes: "",
            reagent_lots: [],
            protocol: 2,
            is_internal_control: 0,
            lab_notes: "",
        }
    }
    
}

function setClinicalParameters(){
    return {
        'test.clinical.hormono': ["test.clinical.options.no", "test.clinical.options.yes"],
        'test.clinical.diabetes': ["test.clinical.options.no", "test.clinical.options.yes"],
        'test.clinical.tobacco': ["test.clinical.options.never", "test.clinical.options.ongoing", "test.clinical.options.passed"],
    }
}

export let clinicalParameters = setClinicalParameters()

i18n.on('loaded languageChanged', () => {
    clinicalParameters = setClinicalParameters()
})

export function updateData(data: any){
    return data
}