import { Select, Stack, FormLabel, Checkbox, MenuItem, Grid, TextField, Box, FormControlLabel, FormHelperText, FormControl, Autocomplete} from '@mui/material';
import { useEffect, useState } from 'react';
import { BasicDateTimePicker } from '../DatePick';
import { httpClient } from '../../index'
import { Controller } from 'react-hook-form';
import { InternalControl, notAllowedCharacters } from "../../helpers/sample.helper"
import { useTranslation } from 'react-i18next';

type Props = {
    register: any,
    control: any,
    id: string | undefined,
    getValues: any
}

export default function Sample({register, control, id, getValues}: Props){
    const { t, i18n } = useTranslation();

    const [users, setUsers] = useState<Object[]>([])
    const [icTests, setIcTests] = useState<InternalControl[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        httpClient.get('/users/').then((response) => {
            setUsers(response.data.results)
        })
        if(getValues('blood_sample.internal_control') !== ""){
            httpClient.get(`/internal_controls/serials/?search=${getValues('blood_sample.internal_control')}`).then((response) => {                    
                setIcTests(response.data.results)
            })
        } else { getICSerials() }
    }, [])

    const getICSerials = () => {
        setLoading(true)
        const date = id === undefined? new Date() : new Date(getValues('blood_sample.sample_datetime'))
        httpClient.get(`/internal_controls/serials/${date.getDate()}${date.getMonth() + 1}${date.getFullYear()}`).then((response) => {
            setIcTests(response.data.results)
        }).finally(() => setLoading(false))
    }

    function filtering(inputValue: string){
        if(inputValue !== ""){
            httpClient.get(`/internal_controls/serials/?search=${inputValue}`).then((response) => {
                setIcTests(response.data.results)
            })
        } else {
            const date = id === undefined? new Date() : new Date(getValues('blood_sample.sample_datetime'))
            httpClient.get(`/internal_controls/serials/${date.getDate()}${date.getMonth() + 1}${date.getFullYear()}`).then((response) => {
                setIcTests(response.data.results)
            })
        }
        return icTests.map((test) => test.blood_sample_pk)
    }

    return (
        <Box sx={{
            '& .MuiOutlinedInput-root': { width: {xs: '20ch', sm: '25ch', md: '30ch', xl: '40ch' }},
        }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Stack spacing={2} direction="column" justifyContent='space-evenly' alignItems="flex-start">
                        <FormLabel required>{t('test.sampleTab.sampleId')}</FormLabel>
                        <Controller control={control} name="blood_sample.sample_id" rules={{ 
                            required: t('test.sampleTab.validation.sampleId'),
                            validate: value => notAllowedCharacters.some(char => value.includes(char)) === false || t('test.messages.charNotAllowed') }}
                            render={({field, fieldState: { error } }) => (
                                <TextField size='small' name="blood_sample.sample_id" value={field.value} onChange={(value) => field.onChange(value)} error={!!error} helperText={error?.message}/>
                            )} />

                        <FormLabel required>{t('test.sampleTab.bloodSampleDate')}</FormLabel>
                        <Controller control={control} name="blood_sample.sample_datetime" rules={{ required: t('test.sampleTab.validation.bloodSampleDate') }} render={({field, fieldState: {error}}) => (
                            <BasicDateTimePicker field={field} isError={error}/>
                        )} />

                        <FormLabel required>{t('test.sampleTab.cellCultureStartTime')}</FormLabel>
                        <Controller control={control} name="blood_sample.cell_culture_start_time" render={({field}) => (
                            <BasicDateTimePicker field={field}/>
                        )}  />
                        
                        <FormLabel required>{t('test.sampleTab.cellCultureEndTime')}</FormLabel>
                        <Controller control={control} name="blood_sample.cell_culture_end_time" render={({field}) => (
                            <BasicDateTimePicker field={field}/>
                        )}  />

                        <FormLabel required>{t('test.sampleTab.stressCondition')}</FormLabel>
                        <Controller control={control} name="blood_sample.stress_condition" render={({field}) => (
                            <Select size='small' name='blood_sample.stress_condition' value={field.value} onChange={(value) => field.onChange(value)}>
                                <MenuItem key="chemical" value="chemical">{t('test.sampleTab.stress_condition_l.chemical')}</MenuItem>
                                <MenuItem key="irradiation" value="irradiation">{t('test.sampleTab.stress_condition_l.irradiation')}</MenuItem>
                            </Select>
                        )} />

                        <FormLabel required>{t('test.sampleTab.labReceptionForm')}</FormLabel>
                        <Controller control={control} name="blood_sample.lab_reception_form" render={({ field }) => (
                            <FormControlLabel control={<Checkbox value={true} checked={field.value} onChange={(value) => field.onChange(value)}/>} label={t('test.sampleTab.presentAndFilled')}/>
                        )}/>

                        <FormLabel required>{t('test.sampleTab.usableSample')}</FormLabel>
                        <Controller control={control} name="blood_sample.usable_sample" render={({ field }) => (
                            <FormControlLabel control={<Checkbox value={true} checked={field.value === 0 ? false : true} onChange={(value) => field.onChange(value.target.checked === false ? 0 : 1)}/>} label={t('test.sampleTab.usable')}/>
                        )}/>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={2} direction="column" justifyContent="space-evenly" alignItems="flex-start">
                        <FormLabel>{t('test.sampleTab.internalControl')}</FormLabel>
                        <Controller control={control} name="blood_sample.internal_control" render={({field}) => (
                            <Autocomplete 
                                options={icTests.length ? icTests.map((test) => test.blood_sample_pk) : []}
                                getOptionLabel={(tests) => `${icTests.length && icTests.find((test) => tests === test.blood_sample_pk)?.serial_number}`}
                                renderInput={(params) => <TextField {...params}/>}
                                size="small"
                                onChange={(_event, newInputValue) => field.onChange(newInputValue)}
                                value={icTests.length ? (field.value ? field.value : null) : null}
                                onInputChange={(_event, inputValue) => filtering(inputValue)}
                                loading={loading}
                            />
                            )}
                        />

                        <FormLabel>{t('test.sampleTab.irradiation_date')}</FormLabel>
                        <Controller control={control} name="blood_sample.irradiation_timestamp" render={({field}) => (
                            <BasicDateTimePicker field={field}/>
                        )}  />

                        <FormLabel required>{t('test.sampleTab.operatedBy')}</FormLabel>
                        <Controller control={control} name="blood_sample.lab_reception_user" rules={{ required: t('test.sampleTab.validation.operatedBy')}} render={({field, fieldState: {error}}) => (
                            <FormControl error={error? true : false}>
                            <Select size='small' value={users.length ? (field.value === undefined ? "" : field.value) : ""} onChange={(value) => field.onChange(value)}>
                                {users.map((user: any, index) => {
                                    return (
                                        <MenuItem key={index} value={user.pk}>{user.first_name} {user.last_name}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>{error?.message}</FormHelperText>
                            </FormControl>
                        )} />

                        <FormLabel required>{t('test.sampleTab.protocol')}</FormLabel>
                        <Controller control={control} name="blood_sample.protocol" render={({field}) => (
                            <Select size='small' name='blood_sample.protocol' value={field.value} onChange={(value) => field.onChange(value)}>
                                <MenuItem key={0} value={1}>{t('test.sampleTab.protocols.protocol1')}</MenuItem>
                                <MenuItem key={1} value={2}>{t('test.sampleTab.protocols.protocol2')}</MenuItem>
                                <MenuItem key={2} value={3}>{t('test.sampleTab.protocols.protocol3')}</MenuItem>
                                <MenuItem key={3} value={4}>{t('test.sampleTab.protocols.protocol4')}</MenuItem>
                            </Select>
                        )} />
                       
                        <FormLabel>{t('test.sampleTab.sampleNotes')}</FormLabel>
                        <TextField multiline {...register("blood_sample.notes")} rows={5}/>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}